var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("input", {
    staticClass: "input-date",
    class: {
      valid: _vm.state === true,
      invalid: _vm.state === false,
      disabled: _vm.disabled,
    },
    attrs: {
      type: "date",
      disabled: _vm.disabled,
      min: _setup.inputMin,
      max: _setup.inputMax,
    },
    domProps: { value: _setup.inputValue },
    on: {
      input: function ($event) {
        return _setup.handleInput($event.target.value)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }