import { AppError } from "@/lib/error";
import { SYSLOG_SEVERITIES } from "@/lib/severities";

export class Actions {
  // container é um objeto que contém todo o context de dados disponivel para a execução da ação e também será o local onde cada action será construída. na prática será provavelmente o módulo da store que instanciou esse objeto de actions. esse design permite que o módulo tenha as ações acessíveis de forma eloquente como em authModule.signIn
  container;

  constructor({ container = {}, options = {} } = {}) {
    this.container = container;

    Object.entries(options).forEach(([name, option]) => {
      if (typeof option !== "function") {
        throw new AppError({
          message: `action ${name} is not a function`,
          severity: SYSLOG_SEVERITIES.CRITICAL,
        });
      }
      container[name] = payload => option({ context: container, payload });
    });
  }
}
