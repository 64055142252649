<script setup>
import { debounce } from "@/lib/async";
import { ref } from "vue";

const props = defineProps({
  anchorBox: {
    required: true,
    type: Object,
  },
  isBusy: {
    default: false,
    type: Boolean,
  },
  rows: {
    required: true,
    type: Number,
  },
});
const emit = defineEmits(["scrollEnd"]);

const previousBottomDistance = ref(Number.MAX_SAFE_INTEGER);
const debouncedEmit = debounce(() => emit("scrollEnd"), 200);

function handleScroll(event) {
  if (props.isBusy) return;

  const el = event.target;
  if (!el) return;

  const scrollBottom = el.scrollTop + el.clientHeight;
  // as vezes a distância é um float próximo de zero, aqui arredondamos para baixo para detectar mais fácil que estamos no final da rolagem
  const bottomDistance = Math.floor(el.scrollHeight - scrollBottom);

  // bottomDistance is 0 é útil quando o conjunto de opções foi reduzido por exemplo em buscas
  const isScrollingDown = bottomDistance < previousBottomDistance.value || bottomDistance === 0;
  previousBottomDistance.value = bottomDistance;

  if (!isScrollingDown) return;

  const threshold = 20;
  const nearEnd = bottomDistance < threshold;
  if (nearEnd) debouncedEmit();
}
</script>
<template>
  <div
    class="dropdown-surface"
    :style="{
      '--rows': rows,
      '--anchor-height': `${anchorBox.height}px`,
      '--anchor-left': `${anchorBox.left}px`,
      '--anchor-top': `${anchorBox.top}px`,
      '--anchor-width': `${anchorBox.width}px`,
    }"
  >
    <div class="dropdown-surface__static">
      <slot name="static" />
    </div>
    <div
      class="dropdown-surface__scrollable"
      @scroll.passive="handleScroll"
    >
      <slot name="scrollable" />
    </div>
  </div>
</template>
<style scoped>
.dropdown-surface {
  position: fixed;
  margin: 0;
  padding: 0;
  padding-block: var(--w-size-20);
  top: calc(var(--anchor-top) + var(--anchor-height) + 1px);
  left: var(--anchor-left);
  width: var(--anchor-width);

  border: var(--w-border-neutral);
  box-shadow: var(--w-shadow-neutral);
}

.dropdown-surface__scrollable {
  max-height: calc(var(--input-lookup-font-size) * 2 * var(--rows));
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
