import { isArrayFull } from "@/lib/aggregates";

export function createQueryPage(pageDispatch) {
  return async ({ context, payload: pageToQuery }) => {
    try {
      context.isQuerying.value = true;

      const { items: pageItems, pagination } = await pageDispatch(pageToQuery);

      context.totalRemote.value = pagination.size;
      context.queriedPages.add(pageToQuery);

      if (!isArrayFull(pageItems)) return;

      const manifest = pageItems.map(i => ({ operation: "set", payload: i }));
      context.setBatch(manifest);
    }
    finally {
      context.isQuerying.value = false;
    }
  };
}
