<template>
  <DialogForm
    :default-payload="defaultPayload"
    :external-errors="externalErrors"
    show-action-divider
    @save="$emit('save', $event)"
  >
    <template #body="{ payload }">
      <ControlInput
        v-model="payload.nome"
        field="nome"
        rules="required|min:3|max:255"
        label="Nome"
        :disabled="editDisabled"
      />
      <ControlSelectMulti
        v-model="payload.gestores"
        field="gestores"
        :options="gestores"
        value-field="id"
        text-field="nome"
        rules="required"
        :rows="3"
        label="Gestores"
        placeholder="Escolha os demais gestores desse grupo."
        :disabled="editDisabled"
      />
      <ControlTextarea
        v-model="payload.descricao"
        field="descricao"
        rules="required|min:3|max:255"
        label="Descrição"
        :disabled="editDisabled"
      />
      <ControlSet legend="Assinatura Padrão">
        <AlertText
          text="Os fluxos desse grupo adotarão as opções de tipo de assinatura conforme as opções a seguir."
        />
        <ApprovalIcp
          v-model="payload.standardApprovalIsIcp"
          :disabled="editDisabled"
        />
        <ControlCheckbox
          v-model="payload.standardApprovalBlocked"
          field="approval-blocked"
          placeholder="Bloquear alteração do tipo de assinatura"
          switch
          :disabled="editDisabled"
        />
      </ControlSet>
      <ControlCheckbox
        v-if="!hideAtivo"
        v-model="payload.ativo"
        field="ativo"
        :hide="hideAtivo"
        placeholder="Ativar Grupo"
        switch
        :disabled="editDisabled"
      />
    </template>
    <template #actions>
      <slot name="actions" />
    </template>
  </DialogForm>
</template>

<script>
import { createDefaultGroup } from "@/data";
import { AlertText, ControlCheckbox, ControlInput, ControlSelectMulti, ControlSet, ControlTextarea, DialogForm } from "@/lib";
import { ApprovalIcp } from "@/views/widgets/approval-icp";
import { mapGetters } from "vuex";

export default {
  components: { AlertText, ApprovalIcp, ControlCheckbox, ControlInput, ControlSelectMulti, ControlSet, ControlTextarea, DialogForm },
  computed: {
    ...mapGetters("gestores", { gestores: "getAllItems" }),
  },
  name: "GroupForm",
  props: {
    defaultPayload: {
      default: createDefaultGroup,
      type: Object,
    },
    editDisabled: {
      default: false,
      type: Boolean,
    },
    externalErrors: {
      default: null,
      type: [Object, Error],
    },
    hideAtivo: {
      default: false,
      type: Boolean,
    },
  },
};
</script>
