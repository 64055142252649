var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "input-lookup", attrs: { id: _setup.parentId } },
    [
      _c(
        _setup.DisplayValue,
        _vm._b(
          { attrs: { selected: _setup.selected, "is-busy": _vm.isBusy } },
          "DisplayValue",
          _setup.anchorProperties,
          false
        )
      ),
      _vm._v(" "),
      _c(
        _setup.DropdownSurface,
        _vm._b(
          {
            attrs: {
              rows: _vm.rows,
              "anchor-box": _setup.anchorBox,
              "is-busy": _vm.isBusy,
            },
            on: { scrollEnd: _setup.handleRequest },
            scopedSlots: _vm._u([
              {
                key: "static",
                fn: function () {
                  return [
                    _c(_setup.InputFilter, {
                      attrs: { add: _vm.add },
                      on: { input: _setup.handleRequest },
                      model: {
                        value: _setup.filter,
                        callback: function ($$v) {
                          _setup.filter = $$v
                        },
                        expression: "filter",
                      },
                    }),
                    _vm._v(" "),
                    _setup.isArrayFull(_setup.selected) ||
                    _setup.isArrayFull(_setup.unselected)
                      ? _c("hr", { staticClass: "input-dropdown__divider" })
                      : _vm._e(),
                  ]
                },
                proxy: true,
              },
              {
                key: "scrollable",
                fn: function () {
                  return [
                    _c(_setup.ListSelected, {
                      attrs: { options: _setup.selected },
                      on: { toggle: _setup.handleUnselected },
                    }),
                    _vm._v(" "),
                    _setup.isArrayFull(_setup.selected) &&
                    _setup.isArrayFull(_setup.unselected)
                      ? _c("hr", { staticClass: "input-dropdown__divider" })
                      : _vm._e(),
                    _vm._v(" "),
                    _c(_setup.ListUnselected, {
                      attrs: { options: _setup.unselected },
                      on: { toggle: _setup.handleSelected },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          "DropdownSurface",
          _setup.popoverProperties,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }