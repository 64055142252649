var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "dropdown-surface",
      style: {
        "--rows": _vm.rows,
        "--anchor-height": `${_vm.anchorBox.height}px`,
        "--anchor-left": `${_vm.anchorBox.left}px`,
        "--anchor-top": `${_vm.anchorBox.top}px`,
        "--anchor-width": `${_vm.anchorBox.width}px`,
      },
    },
    [
      _c(
        "div",
        { staticClass: "dropdown-surface__static" },
        [_vm._t("static")],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dropdown-surface__scrollable",
          on: {
            "&scroll": function ($event) {
              return _setup.handleScroll.apply(null, arguments)
            },
          },
        },
        [_vm._t("scrollable")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }