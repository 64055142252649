import { isDate } from "../is-date";

export function fromDateToStringParts({ date = undefined, utc = false } = {}) {
  if (!isDate(date)) return undefined;

  let year;
  let month;
  let day;
  let hours;
  let minutes;

  if (utc) {
    year = String(date.getUTCFullYear()).padStart(4, "0");
    month = String(date.getUTCMonth() + 1).padStart(2, "0");
    day = String(date.getUTCDate()).padStart(2, "0");
    hours = String(date.getUTCHours()).padStart(2, "0");
    minutes = String(date.getUTCMinutes()).padStart(2, "0");
  }
  else {
    year = String(date.getFullYear()).padStart(4, "0");
    month = String(date.getMonth() + 1).padStart(2, "0");
    day = String(date.getDate()).padStart(2, "0");
    hours = String(date.getHours()).padStart(2, "0");
    minutes = String(date.getMinutes()).padStart(2, "0");
  }

  return { day, hours, minutes, month, year };
}
