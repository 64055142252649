import { Actions } from "./actions";
import { State } from "./state";

export class Module {
  _actions;
  _state;
  store;

  constructor(options = {}) {
    this.store = options.store;

    this._state = new State({
      container: this,
      options: options.state,
    });

    this._actions = new Actions ({
      container: this,
      options: options.actions,
    });
  }

  patch(options) {
    this._state.patch(options);
  }

  reset() {
    this._state.reset();
  }
}
