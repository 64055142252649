import { ItemsModule } from "../items";
import { createQueryNext } from "./query-next";
import { createQueryPage } from "./query-page";

export class CollectionModule extends ItemsModule {
  constructor(options) {
    options.state = {
      ...options.state,
      isFull: context => (context.totalRemote !== null || context.totalRemote !== undefined) && context.totalRemote <= context.total,
      isQuerying: false,
      queriedPages: new Set(),
      totalRemote: null,
    };

    options.actions = {
      ...options.actions,
      queryNext: createQueryNext(),
      queryPage: createQueryPage(options.collection.pageDispatch),
    };

    super(options);
  }
}
