<script setup>
import { useExecute, useQueryState } from "@/lib/components/async";
import { CardBase } from "@/lib/components/dialogs";
import { NavBar, NavCrumb, SideBar } from "@/lib/components/navs";
import { ref } from "vue";

import SkeletonLoader from "./skeleton-loader.vue";

const props = defineProps({
  afterFirstQuery: {
    default: null,
    type: Object,
  },
  crumbs: {
    required: true,
    type: Array,
  },
  headerText: {
    required: true,
    type: String,
  },
  noCard: {
    default: false,
    type: Boolean,
  },
  relatedStoreModules: {
    default: null,
    type: Array,
  },
  showLinear: {
    default: false,
    type: Boolean,
  },
  showOverlay: {
    default: false,
    type: Boolean,
  },
  sideLinks: {
    required: true,
    type: Array,
  },
  store: {
    default: null,
    type: Object,
  },
  storeModules: {
    default: null,
    type: Array,
  },
});

const { isBusyWithFirstQuerying, isBusyWithQuerying } = useQueryState({
  afterFirstQuery: props.afterFirstQuery,
  modules: props.storeModules,
  store: props.store,
});
const { isBusyWithQuerying: isBusyWithRelatedQuerying } = useQueryState({
  modules: props.relatedStoreModules,
  store: props.store,
});
const { execute, isBusy: isBusyWithExecute, lastError } = useExecute();

const showSideBar = ref(false);
</script>
<template>
  <div class="dashboard-page">
    <NavBar
      :is-open="showSideBar"
      @update:isOpen="showSideBar = $event"
    >
      <template #logo>
        <slot name="navStart" />
      </template>
      <template #links>
        <slot name="navEnd" />
      </template>
    </NavBar>

    <SideBar
      id="navSidebar"
      :links="sideLinks"
      :is-open="showSideBar"
      @update:isOpen="showSideBar = $event"
    />
    <div class="p-2 p-sm-5">
      <NavCrumb
        data-test="nav-crumbs"
        :path="crumbs"
        class="d-none d-sm-flex"
      />
      <!-- o overlay é ativado durante a primeira query para permitir que telas de edição consigam e inclusão consigam carregar os dados originais e de listagem antes de o usuário começar a editar campos. caso contrário ele vai editar campos em branco sem saber o dado real daquele registro que ele está editando  -->
      <CardBase
        :header-text="headerText"
        :show-overlay="isBusyWithExecute || isBusyWithFirstQuerying || showOverlay"
        :show-linear="isBusyWithQuerying || isBusyWithRelatedQuerying || showLinear"
        :transparent="noCard"
        :tight="noCard"
      >
        <main>
          <!-- o template abaixo serve para adiar apresentação de conteúdo enquanto ainda se trabalha na primeira query. ele é útil em paginas de edição, por exemplo, onde essa tática evita ativar regras de formulário ou outros elementos da ui antes que os dados de negócio iniciais estejam carregados -->
          <template v-if="isBusyWithFirstQuerying && afterFirstQuery">
            <SkeletonLoader />
          </template>
          <template v-else>
            <!-- o uso dos mecanismos de query e execute são opcionais em cada página -->
            <slot
              name="content"
              :execute="execute"
              :last-error="lastError"
              :is-busy-with-first-querying="isBusyWithFirstQuerying"
              :is-busy-with-querying="isBusyWithQuerying"
              :is-busy-with-execute="isBusyWithExecute"
              :is-busy-with-related-querying="isBusyWithRelatedQuerying"
            />
          </template>
        </main>
      </CardBase>
    </div>
  </div>
</template>
<style scoped>
.dashboard-page {
  background-color: var(--w-color-neutral-20);
  min-height: 100vh;
}
</style>
