<script setup>
import { isArrayFull } from "@/lib/aggregates";
import { kebabCase } from "@/lib/strings";
import { computed, ref, toRefs } from "vue";

import DisplayValue from "./display-value.vue";
import DropdownSurface from "./dropdown-surface.vue";
import InputFilter from "./input-filter.vue";
import ListSelected from "./list-selected.vue";
import ListUnselected from "./list-unselected.vue";
import { useOptions } from "./use-options";
import { usePopover } from "./use-popover";

const props = defineProps({
  add: {
    default: false,
    type: Boolean,
  },
  disabled: {
    default: false,
    type: Boolean,
  },
  id: {
    required: true,
    type: String,
  },
  isBusy: {
    default: false,
    type: Boolean,
  },
  multi: {
    default: false,
    type: Boolean,
  },
  options: {
    default: () => [],
    type: Array,
  },
  rows: {
    default: 5,
    type: Number,
  },
  textField: {
    default: "text",
    type: String,
  },
  value: {
    default: () => [],
    type: Array,
  },
  valueField: {
    default: "value",
    type: String,
  },
});
const emit = defineEmits(["input", "request"]);

const { options, textField, valueField } = toRefs(props);
const model = computed({
  get() {
    return props.value;
  },
  set(value) {
    emit("input", value);
  },
});
const filter = ref();

const parentId = kebabCase(props.id);
const { anchorBox, anchorProperties, popoverProperties } = usePopover(parentId);
const { handleSelected, handleUnselected, selected, unselected } = useOptions({ filter, model, options, textField, valueField });

function handleRequest() {
  emit("request", filter.value);
}
</script>
<template>
  <div
    :id="parentId"
    class="input-lookup"
  >
    <DisplayValue
      :selected="selected"
      v-bind="anchorProperties"
      :is-busy="isBusy"
    />
    <DropdownSurface
      v-bind="popoverProperties"
      :rows="rows"
      :anchor-box="anchorBox"
      :is-busy="isBusy"
      @scrollEnd="handleRequest"
    >
      <template #static>
        <InputFilter
          v-model="filter"
          :add="add"
          @input="handleRequest"
        />
        <hr
          v-if="isArrayFull(selected) || isArrayFull(unselected)"
          class="input-dropdown__divider"
        >
      </template>
      <template #scrollable>
        <ListSelected
          :options="selected"
          @toggle="handleUnselected"
        />
        <hr
          v-if="isArrayFull(selected) && isArrayFull(unselected)"
          class="input-dropdown__divider"
        >
        <ListUnselected
          :options="unselected"
          @toggle="handleSelected"
        />
      </template>
    </DropdownSurface>
  </div>
</template>
<style scoped>
.input-lookup {
  --input-lookup-font-size: var(--w-font-size-30);
}

.input-lookup {
  position: relative;
  width: 100%;

  font-size: var(--input-lookup-font-size);
  font-weight: var(--w-font-weight-20);
}

.input-dropdown__divider {
  margin-block: var(--w-size-20);
}
</style>
