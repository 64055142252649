import { isObjectFull } from "@/lib/aggregates";

import { CollectionModule } from "../collection";
import { Module } from "../core";

export class Store {
  constructor(modules) {
    Object.entries(modules).forEach(([name, options]) => {
      const module = isObjectFull(options.collection)
        ? new CollectionModule({ store: this, ...options })
        : new Module({ store: this, ...options });

      this[name] = module;
    });
  }

  reset() {
    const modules = Object.values(this.modules);
    modules.forEach(module => module.reset());
  }
}
