<script setup>
import { debounce } from "@/lib/async";
import { ButtonTertiary } from "@/lib/components/buttons";
import { computed } from "vue";

const props = defineProps({
  add: {
    default: false,
    type: Boolean,
  },
  value: {
    default: null,
    type: String,
  },
});
const emit = defineEmits(["input"]);

const debouncedInput = debounce(
  (value) => {
    emit("input", value);
  },
  200,
);

const model = computed({
  get() {
    return props.value;
  },
  set(value) {
    debouncedInput(value);
  },
});
</script>
<template>
  <div class="input-filter">
    <input
      v-model="model"
      type="text"
      class="input-filter__input"
    >
    <ButtonTertiary
      v-if="add"
      label="Novo"
      squared
      size="sm"
    />
  </div>
</template>
<style scoped>
.input-filter {
  padding-inline: var(--w-size-30);
  display: flex;
  gap: var(--w-size-10);
}

.input-filter__input {
  width: 100%;
  padding-inline: var(--w-size-20);
  padding-block: var(--w-size-10);

  border: var(--w-border-neutral);
  outline: none;
  color: var(--w-plain-text-color);

  &:focus {
    box-shadow: var(--w-border-primary);
  }
}
</style>
