export function createGroups(endpoints) {
  return {
    actions: {
      async addGroup({ context, payload: userGroup }) {
        const responseGroup = await endpoints.groupAdd.dispatch(userGroup);
        context.setItem(responseGroup);
      },
      async editGroup({ context, payload: userGroup }) {
        const responseGroup = await endpoints.groupEdit.dispatch(userGroup);
        context.setItem(responseGroup);
      },
    },
    collection: {
      pageDispatch: page => endpoints.groupsList.dispatch(page),
    },
  };
}
