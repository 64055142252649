import { Module } from "../core";
import { removeItem, setBatch, setItem } from "./actions";

export class ItemsModule extends Module {
  constructor(options = {}) {
    options.state = {
      ...options.state,
      items: {},
      list: ({ items }) => Object.values(items.value),
      total: ({ list }) => list.value.length,
    };

    options.actions = {
      ...options.actions,
      removeItem,
      setBatch,
      setItem,
    };

    super(options);
  }
}
