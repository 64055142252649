<script setup>
import { SpinnerBase } from "@/lib/components/async";
import { computed } from "vue";

const props = defineProps({
  isBusy: {
    default: false,
    type: Boolean,
  },
  selected: {
    default: () => [],
    type: Array,
  },
});

const text = computed(() => {
  if (props.selected.length === 0) {
    return "Clique para selecionar";
  }

  if (props.selected.length === 1) {
    return props.selected[0].text;
  }

  return `${props.selected.length} selecionados`;
});
</script>
<template>
  <!-- o uso de button é necessário para se beneficiar da popover api que exige que invokers sejam buttons, isso também habilita uso do teclado para abrir e fechar listagem  -->
  <button
    class="display-selected"
    type="button"
  >
    <div>
      {{ text }}
    </div>
    <SpinnerBase
      v-if="isBusy"
      size="var(--w-size-35)"
    />
  </button>
</template>
<style scoped>
.display-selected {
  position: relative;
  display: flex;
  width: 100%;
  height: calc(var(--input-lookup-font-size) * 2.5);
  padding-inline-start: var(--w-size-30);
  padding-inline-end: var(--w-size-40);
  padding-block: var(--w-size-20);

  justify-content: space-between;

  cursor: pointer;

  border: var(--w-border-neutral);
  background: transparent;

  text-align: left;
  color: inherit;
}

.display-selected:focus {
  box-shadow: var(--w-border-primary);
}

.display-selected::after {
  content: "▼";
  position: absolute;
  right: var(--w-size-20);
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}
</style>
