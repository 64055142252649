export function createQueryNext() {
  return async ({ context }) => {
    if (context.isFull.value) return;

    let page = 0;
    while (context.queriedPages.has(page)) {
      page++;
    }
    await context.queryPage(page);
  };
}
