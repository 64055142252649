import { Store } from "@/lib";

import { createGroups } from "../groups-unified";

export function initUnifiedStore(endpoints) {
  const groups = createGroups(endpoints);

  const unifiedStore = new Store({ groups });

  return unifiedStore;
}
