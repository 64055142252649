<template>
  <DashboardPageBase
    :related-store-modules="collectionsStoreModules"
    :crumbs="crumbs"
    :header-text="headerText"
  >
    <div :id="camelCase('list', 'page', field)">
      <PanelButtons>
        <ButtonSecondary
          v-if="collectionsStoreModules"
          field="update"
          label="Atualizar"
          @click="handleUpdate"
        />
        <slot name="listActions" />
      </PanelButtons>
      <ListPower
        :field="field"
        :items="items"
        :primary-key="primaryKey"
        v-bind="$attrs"
        v-on="$listeners"
      >
        <template #actions="{ item, index }">
          <slot
            name="listItemActions"
            :index="index"
            :item="item"
          />
        </template>
      </ListPower>
    </div>
  </DashboardPageBase>
</template>

<script>
import { ButtonSecondary, PanelButtons } from "@/lib/components/buttons";
import { ListPower } from "@/lib/components/lists";
import { camelCase } from "@/lib/strings";

import { DashboardPageBase } from "../dashboard-page-base";

export default {
  components: { ButtonSecondary, DashboardPageBase, ListPower, PanelButtons },
  methods: {
    camelCase,
    handleUpdate() {
      this.collectionsStoreModules.forEach((module) => {
        const path = `${module}/startRefreshingItemsFromRemote`;
        this.$store.dispatch(path, { now: true });
      });
    },
  },
  name: "DashboardPageList",
  props: {
    collectionsStoreModules: {
      default: null,
      type: Array,
    },
    crumbs: {
      required: true,
      type: Array,
    },
    field: {
      required: true,
      type: String,
    },
    headerText: {
      default: null,
      type: String,
    },
    items: {
      default: () => [],
      type: Array,
    },
    primaryKey: {
      default: "id",
      type: String,
    },
  },
};
</script>
