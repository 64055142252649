import { clone } from "@/lib/aggregates";
import { computed, isRef, ref } from "vue";

export class State {
  // container é um objeto que contém os valores do estado. na prática será provavelmente o módulo da store que instanciou esse objeto de state. esse design permite que o módulo tenha o estado acessível de forma eloquente como em authModule.user.value
  container;
  initial;

  constructor({ container = {}, options = {} } = {}) {
    this.container = container;
    this.initial = clone(options);
    this.reset();
  }

  patch(options) {
    const copy = clone(options);
    Object.entries(copy).forEach(([key, option]) => {
      if (typeof option === "function") {
        this.container[key] = computed(() => option(this.container));
        return;
      }

      if (isRef(this.container[key])) {
        this.container[key].value = option;
        return;
      }

      this.container[key] = ref(option);
    });
  }

  reset() {
    this.patch(this.initial);
  }
}
