<script setup>
import { asDate, fromDateToStringParts } from "@/lib/time";
import { computed } from "vue";

const props = defineProps({
  disabled: {
    default: false,
    type: Boolean,
  },
  max: {
    default: null,
    type: Date,
  },
  min: {
    default: null,
    type: Date,
  },
  state: {
    default: null,
    type: Boolean,
  },
  time: {
    default: "00:00:00.000",
    type: String,
  },
  value: {
    default: null,
    type: Date,
  },
});
const emit = defineEmits(["input"]);

const inputValue = computed(() => fromDateToInputString(props.value));
const inputMin = computed(() => fromDateToInputString(props.min));
const inputMax = computed(() => fromDateToInputString(props.max));

function handleInput(value) {
  const newValue = (value)
    ? asDate(`${value}T${props.time}`)
    : null;
  emit("input", newValue);
}

function fromDateToInputString(date) {
  const parts = fromDateToStringParts({ date });
  if (!parts) return "";
  return `${parts.year}-${parts.month}-${parts.day}`;
}
</script>
<template>
  <input
    class="input-date"
    :class="{ valid: state === true, invalid: state === false, disabled }"
    type="date"
    :value="inputValue"
    :disabled="disabled"
    :min="inputMin"
    :max="inputMax"
    @input="handleInput($event.target.value)"
  >
</template>
<style scoped>
.input-date {
  --size: var(--w-font-size-30);
  --state-shadow: var(--w-border-shadow-neutral);
}

.input-date.valid {
  --state-shadow: var(--w-border-shadow-success);
}

.input-date.invalid {
  --state-shadow: var(--w-border-shadow-danger);
}

.input-date {
  width: 100%;
  height: calc(var(--size) * 2.5);
  padding-inline: var(--w-size-30);
  padding-block: var(--w-size-20);

  border: var(--w-border-neutral);
  outline: none;

  font-family: inherit;
  color: inherit;
  font-size: var(--size);
  font-weight: var(--w-font-weight-20);
}

.input-date:focus {
  box-shadow: var(--state-shadow);
}

.input-date.disabled {
  background-color: var(--w-color-neutral-20);
}
</style>
