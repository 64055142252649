import { asDate, isDate } from "@/lib";

import { decodeDocument, encodeDocument } from "../documents";
import { decodeStakeholder, encodeStakeholder } from "./code-stakeholder";

export function decodeFlow(serverFlow) {
  const { dadosBasicos, documentos, id, interessados, ...recordRest } = serverFlow;
  const { createdAt, dataLimiteResposta, interrompeSeNegativo, sequencial, tipoAssinatura, ...basicosRest } = dadosBasicos;

  const interessadosClient = interessados.map(decodeStakeholder);

  return {
    id,
    ...recordRest,
    dadosBasicos: {
      dataCriacao: asDate(createdAt),
      dataLimiteResposta: asDate(dataLimiteResposta) ?? null,
      isIcp: tipoAssinatura === "QUALIFICADA",
      isInterrompeSeNegativo: interrompeSeNegativo,
      isSequencial: sequencial,
      ...basicosRest,
    },
    documentos: documentos.map(decodeDocument),
    interessados: interessadosClient,
  };
}

export function encodeFlow(clientFlow) {
  const { dadosBasicos, documentos = [], interessados = [], ...itemRest } = clientFlow;
  const { dataLimiteResposta: dataLimiteRespostaClient, isIcp, isInterrompeSeNegativo, isSequencial, ...basicosRest } = dadosBasicos;

  const dataLimiteResposta = isDate(dataLimiteRespostaClient)
    ? dataLimiteRespostaClient.toISOString()
    : null;

  const record = {
    ...itemRest,
    dadosBasicos: {
      interrompeSeNegativo: isInterrompeSeNegativo,
      sequencial: isSequencial,
      tipoAssinatura: isIcp ? "QUALIFICADA" : "AVANCADA",
      ...basicosRest,
      dataLimiteResposta,
    },
    documentos: documentos.map(encodeDocument),
    interessados: interessados.map(encodeStakeholder),
  };

  return record;
}
